/*
* Base button styles
*/

// Make a button look and behave like a link with:
// .btn--link { /* See 0_framework/1_base/links.scss  */}

.btn {
    @apply inline-block;
    @apply px-5;
    @apply py-2;

    @apply border;
    @apply rounded;

    font-weight: theme('fontWeight.medium');
    @apply text-center;
    @apply align-middle;
    @apply select-none;
    @apply whitespace-nowrap;
    @apply no-underline;

    @apply cursor-pointer;

    & + &:not(.btn--full) {
        @apply ml-2;
    }

    .disabled,
    &:disabled {
        @apply opacity-50;
        @apply shadow-none;
        @apply pointer-events-none;
    }

    @apply transition;
}

/*
* Base button variants
*/

.btn--full {
    width: 100%;

    // todo: this doesn't work and i'm not sure why. limited by my sass skills... not even sure what it is doing - RichC
    //@include default-margins() {
    //    + * {
    //        @apply mt-4;
    //    }
    //}
}

/*
* Base button themes
*/

.btn {
    color: theme('colors.white.1');
    border-color: theme('colors.blue.500');
    background-color: theme('colors.blue.500');

    &:hover {
        color: theme('colors.blue.500');
        border-color: theme('colors.blue.500');
        background-color: theme('colors.white.1');
    }
}

.btn--hollow {
    background-color: theme('colors.transparent');
    border-color: theme('colors.black.1');
    color: theme('colors.black.1');
    &:hover {
        color: theme('colors.white.1');
        border-color: theme('colors.blue.500');
        background-color: theme('colors.blue.500');
    }
}
