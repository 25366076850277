.layout-bg {
  background-image: url("/static/images/mobile-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  
  @screen md {
    background-image: url("/static/images/bg-gradient.jpg");
    background-size: 100% auto;
  }
}

.chart-layout-bg {
  background-image: url("/static/images/mobile-chart-gradient.png");
  background-repeat: no-repeat;
  background-size: 100% 365px;

  @screen lg {
    background-image: url("/static/images/chart-gradient.jpg");
    background-size: 80% 365px;
    background-position: top right;
  }
}

.modal-open {
  background: rgba(16, 1, 70, .6);
  position: fixed;
}

.ChartToolTip {
  @apply flex;
  @apply items-stretch;
  @apply relative;
  @apply leading-none;
  @apply mb-2;

  &::before {
    content: ' ';
    display: block;
    margin-right: 6px;
    width: 5px;
    height: auto;
    border-radius: 4px;
  }


}
.ChartToolTip--ours {
  &::before {
    @apply bg-turquoise;
  }
}


.ChartToolTip--theirs {
  &::before {
    @apply bg-fuchsia;
  }
}

.template-homepage {
  min-height: 100vh;
  background: rgb(16,1,70);
  background: radial-gradient(circle, rgba(16,1,70,1) 23%, rgba(0,0,0,1) 100%);
}

.webview {
  padding-top: 46px;
  padding-bottom: 70px;
}

.webview .menu-list {
 height: 100px;
}

.webview .nav-item {
  margin-bottom: 30px;
}