.customScroll::-webkit-scrollbar {
  width: .25rem;

}

.customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  @apply bg-neutral-200;
  @apply rounded;

}

.customScroll::-webkit-scrollbar-thumb {
  @apply bg-neutral-100;
  @apply rounded;
}

// Custom scrollbar for Graph/Chart container
.customScroll {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-scrollbar-corner,
  &::-webkit-resizer {
      display: none;
  }
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 10px #382188;
	border-radius: 10px;
    border: 3px solid #100146;
    @apply lg:mr-14;
    @apply mr-6;

  }
  &::-webkit-scrollbar-thumb {
    position: relative;
    border-radius: 10px;
	background-color: #ffffff;
    &::after {
      content: "| | |";
      position: absolute;
      left: 0;
    }
  }
}
