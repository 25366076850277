/**
 * Containers set the highest order layout property for site content.
 * Specifically they apply consistent horizontal padding to content, and
 * they can limit the max width of content.
 *
 * Content that needs to bleed beyond this should either just use .container-spacing or
 * apply styling in a div wrapping the .container element.
 */


%container-spacing {
    @apply px-6;
    @apply mx-auto;
    @screen sm {
        @apply px-8;
        @apply mx-auto;
    }
}

.container-spacing {
    @extend %container-spacing;
}

.container-width {
    @apply mx-auto;
    max-width: var(--site-width);
}

.container {
    @extend %container-spacing;
    max-width: var(--site-width);
}
