// Extra custom styling for dropdown `react-select`
.group-heading-wrapper {
  + div {
    @apply flex;
    @apply flex-col;
    @apply gap-y-1.5;
    &.hidden {
      display: none !important;
    }
  }
}