// todo: consider making a tailwindcss plugin.

.collapse {
    @apply hidden;

    &.collapsible-show {
        @apply block;
        // @todo: add transition
    }
}
